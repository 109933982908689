$bu: ci;
@import '../../custom';

.guidance-doc__container {
  width: 98.5%;
  min-height: 4.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1rem;

  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.15);

  .guidance-doc__file-name-container {
    float: left;

    display: flex;
    align-items: center;

    .guidance-doc__file-name-icon {
      font-size: 1.5rem;
      margin-right: 1rem;
    }

    .guidance-doc__file-name {
      font-weight: 600;
    }
  }

  .guidance-doc__download-button {
    float: right;

    background-color: $primary;
    border-color: $primary;

    padding: 0.5rem 1.25rem;

    .guidance-doc__download-icon {
      margin-right: 0.5em;
    }
  }
}
