$bu: ci;
// Override default variables before the import

$font-family-sans-serif: 'Source Sans Pro', sans-serif;
$tooltip-max-width: 379px;
$table-cell-padding: 1rem;

$primary: #037fac;
$body-color: black;
$modal-backdrop-opacity: 0.77 !important;
$light: #f9f9f9;
$lightButNotTooLight: #eeeeee;
$e4: #e4e4e4;
$ddd: #dddddd;
$color333: #333;
$danger: #de3535;
$redonblack: #ff5050;
$secondary: #666;
$success: #018503;
$black37: #a1a1a1;
$candidate-bg: #f4f4f4;
$candidate-list-border: 1px solid $ddd;
$progress-bg: $ddd;

$dropdown-bg: #272727;
$dropdown-link-color: #ffffff;
$dropdown-link-hover-bg: #000000;
$dropdown-link-hover-color: #ffffff;
$dropdown-divider-bg: #373737;
$dropdown-padding-y: 0;

$modal-sm: 500px !default;
$modal-md: 680px !default;
$modal-lg: 975px !default;

$font-small: 16px;
$font-medium: 18px;
$font-large: 20px;

@if ($bu == ocr) {
  $primary: #20234e;
}
$primary37: transparentize($primary, 0.37);
$theme-colors: (
  'primary37': $primary37,
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'danger': $danger,
  'light': $light,
  'lightButNotTooLight': $lightButNotTooLight,
  'black37': $black37,
  'ddd': $ddd,
  'e4': $e4,
  'color333': $color333,
  'transparent': transparent,
);
$table-accent-bg: $light;
$input-focus-border-color: transparent;
$input-padding-y: 1rem;
$input-padding-x: 1rem;
$input-focus-box-shadow: 0 0 0 2px rgba(3, 127, 172, 0.37);
$grid-columns: 24 !default;
$grid-gutter-width: 16px !default;

$border-radius: 8px !default;
$border-radius-large: 12px !default;
$border-radius-small: 4px !default;
$border-grey: $black37;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    25: $spacer * 0.75,
    3: $spacer,
    35: $spacer * 1.25,
    4: $spacer * 1.5,
    42: $spacer * 2,
    45: $spacer * 2.5,
    5: $spacer * 3,
    55: $spacer * 3.5,
    6: $spacer * 4,
    65: $spacer * 8,
    7: $spacer * 11,
  ),
  $spacers
);

$negative-spacers: negativify-map($spacers) !default;
.empty-list {
  min-height: 325px;
}
// Import Bootstrap and its default variables

@import '~bootstrap/scss/bootstrap.scss';

html,
body,
#root {
  height: 100%;
}
body {
  overflow-y: scroll;
}
.table {
  border-spacing: 0;
  border-collapse: separate;
}
.table thead th {
  border-bottom-width: 0px;
}
table tbody tr:last-child td:first-child,
table tbody tr:last-child th:first-child {
  border-bottom-left-radius: 8px;
}
table td {
  vertical-align: middle !important;
}
table thead tr:first-child th:last-child {
  border-top-right-radius: 8px;
  border-right: 1px solid #ddd;
}
table thead tr:first-child th:first-child {
  border-top-left-radius: 8px;
  border-left: 1px solid #ddd;
}
table tbody tr td,
table tbody tr th {
  border-left: 1px solid #ddd;
}
table tbody tr:last-child td,
table tbody tr:last-child th {
  border-bottom: 1px solid #ddd;
}
table tbody tr td:last-child {
  border-right: 1px solid #ddd;
}
table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.row-card {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  .card-body {
    padding: 0.75rem 0;
  }
}

.modal-627 {
  max-width: 627px;
}
.adjacent + .adjacent {
  border-top: 1px solid #ddd;
}
.ribbon {
  background: $primary;
  color: white;
  position: relative;
  margin-right: -10px;
  &:before {
    content: '';
    display: inline-block;
    height: 0;
    width: 0;
    top: 0;
    position: absolute;
    left: -15px;
    border-bottom: 18px solid $primary;
    border-top: 18px solid $primary;
    border-left: 15px solid transparent;
  }
  @include media-breakpoint-up(sm) {
    &:after {
      content: '';
      display: inline-block;
      height: 0;
      width: 0;

      bottom: -10px;
      position: absolute;
      right: 0;

      border-top: 10px solid #00587b;
      border-right: 10px solid transparent;
    }
  }
}
.paused-col {
  max-width: calc(100% - 51px) !important;
  @include media-breakpoint-up(md) {
    max-width: calc(100% - 110px) !important;
  }
  & + .col {
    flex: 0 0 50px !important;
    @include media-breakpoint-up(md) {
      flex: 0 0 110px !important;
    }
  }
}
.no-text-hover {
  &:hover {
    text-decoration: none !important;
  }
}

.table-striped .tbody > div:nth-of-type(even) {
  background-color: #f9f9f9;
}
.table .tbody .trow > div:last-child {
  border-right: none !important;
}
.table .tbody > .trow:last-child div {
  border-bottom: none !important;
}
.plan-widget {
  min-height: 64px;
  min-width: 45px;
  padding-left: 0;
  padding-right: 0;

  font-size: 1rem;

  @include media-breakpoint-up(sm) {
    min-width: 64px;
    font-size: 1.25rem;
  }
}
.not-rounded-when-xs {
  @include media-breakpoint-down(xs) {
    border-radius: 0 !important;
  }
}
.not-border-when-xs {
  @include media-breakpoint-down(xs) {
    border: 0 !important;
  }
}
.border-bottom-when-xs {
  @include media-breakpoint-down(xs) {
    border-bottom: 1px solid #dee2e6 !important;
  }
}
.not-border-x-when-xs {
  @include media-breakpoint-down(xs) {
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

.bglight--when-xs {
  @include media-breakpoint-down(xs) {
    background-color: $light;
  }
}

.edge-to-edge-xs {
  margin-left: -$grid-gutter-width / 2;
  margin-right: -$grid-gutter-width / 2;
  @include media-breakpoint-up(sm) {
    background-color: $white !important;
    margin-left: 0;
    margin-right: 0;
  }
}

.text-black-37 {
  color: rgba(0, 0, 0, 0.37);
}

.border-2 {
  border-width: 2px !important;
}
.border-dashed {
  border-style: dashed !important;
}

.cj-calendar {
  min-height: 415px;
}
.navbar-light .navbar-nav .nav-link {
  color: $color333;
}
link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: $primary;
}
.navbar-brand {
  font-size: 1.5rem;
}
.modal-auto {
  display: flex !important;
}
.opacity-59 {
  opacity: 0.59;
}
.btn-outline-primary {
  border-color: $ddd;
}
.font-normal {
  font-size: 1rem;
}

.react-select__menu {
  border-radius: 0.5rem;
}

.custom-scroll::-webkit-scrollbar-track,
.react-select__menu-list::-webkit-scrollbar-trac {
  border-radius: 10px;
  background-color: #e7e7e7;
}
.btn:focus {
  box-shadow: none;
}
.input-focus {
  box-shadow: $input-focus-box-shadow;
}
.react-select__control--is-focused {
  box-shadow: $input-focus-box-shadow !important;
}
.react-select__control {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  @include media-breakpoint-up(md) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
.custom-scroll::-webkit-scrollbar,
.react-select__menu-list::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar-thumb,
.react-select__menu-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $black37;
}
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}
.custom-scroll-sync::-webkit-scrollbar-thumb,
.react-select__menu-list::-webkit-scrollbar-thumb {
  background-color: #000;
}
.close {
  opacity: 1 !important;
}
.modal-dialog-auto {
  .modal-title {
    flex-grow: 1 !important;
  }
  .close {
    position: absolute;
    right: 1rem;
  }
  max-width: 1920px;
  width: 70%;
  display: inline-block;
}
.form-control {
  height: inherit;
}
.append-inset {
  z-index: 4;
}

.project-main {
  @include media-breakpoint-up(md) {
    max-width: calc(100% - 16rem);
  }
}

.row-input {
  input {
    &:focus {
      box-shadow: none;
    }
  }
}
.step-main {
  width: 100%;
  .wizard-step {
    background: transparent;
    border: 0;

    .card-header,
    .card-body,
    .card-footer {
      background: transparent;
      border: 0;
      padding: 1.5rem 1.5rem;

      @include media-breakpoint-up(sm) {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
      }
    }

    .card-body {
      @include media-breakpoint-up(lg) {
        padding-top: 2.5rem;
      }
      .wizard-label {
        margin-bottom: 1rem;
      }
    }
  }
  @include media-breakpoint-up(md) {
    flex: 1 1 25rem;

    min-height: calc(100vh - 77px);
  }
  @include media-breakpoint-up(lg) {
    flex: 1 1 30rem;

    min-height: calc(100vh - 77px);
  }
  @include media-breakpoint-up(xl) {
    flex: 0 1 32rem;
  }
}
.pointer {
  &:hover {
    cursor: pointer;
  }
}
.btn-outline-ddd {
  color: $secondary;
}
.btn-nofocus {
  &:focus {
    box-shadow: none;
  }
}
button.bg-light:focus {
  background-color: $light !important;
}
.btn-back {
  padding: 0 !important;
  border: 0;
  &:hover,
  &:active {
    background-color: transparent !important;
    text-decoration: underline !important;
  }
}
.fill-secondary {
  fill: $secondary;
}
.w-422 {
  width: 100px;
}
.grayscale-100 {
  filter: grayscale(100);
}
.grayscale-0 {
  filter: grayscale(0);
}
.progress-slim-3 {
  height: 0.75rem;
}
.nav-user-profile {
  border-radius: 50% 50%;
  max-width: 2.75rem;
  height: auto;
}

.rounded-extra {
  border-radius: 0;
}
.rounded-small {
  border-radius: $border-radius-small !important;
}
.form-control.is-invalid,
.form-control.is-invalid:focus,
.bs-danger {
  border-color: transparent !important;
  box-shadow: 0px 0px 0 2px rgba(222, 53, 53, 0.37);
  background-image: none;
}
.bs-danger-inset {
  border-color: rgba(222, 53, 53, 0.37) !important;
  box-shadow: inset 0px 0px 0 1px rgba(222, 53, 53, 0.37);
}
.btn {
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.btn-white {
  background-color: white;
}
.btn-link {
  color: $secondary;
  font-size: 1rem;
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}
.nounderline {
  text-decoration: none;
}
.bullet-list {
  list-style: none;
}
.bullet-list li {
  margin-bottom: 0.25rem;
  position: relative;
}
.bullet-list li::before {
  content: '●';
  color: #d7d7d7;
  position: absolute;
  left: -1.5rem;
  top: -0.3rem;
  font-size: 1.4rem;
}

.bigc {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  width: 1920px;
}

.bullet-primary::before {
  color: $primary !important;
}
.border-top-accent {
  border-top-width: 3px !important;
}
.border-top-accent-active {
  border-top-color: $primary !important;
}
input {
  border-radius: 0.5rem !important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
.opacity-37 {
  opacity: 0.37;
}
.opacity-37-children > * {
  opacity: 0.37;
}

.tabs-detailed {
  border: 0;
  .nav-link {
    cursor: pointer;
    border-radius: 0 !important;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-top: 0;

    &.active {
      cursor: default;
      color: $primary;
      border-color: transparent;
      .btn-active {
        border-width: 2px !important;
        border-color: $primary !important;
        background-color: $light;
      }
    }
  }
}
.invalid-feedback {
  font-size: 1rem;
  font-weight: bold;
}

.step-tip {
  width: 100%;

  padding: 1.5rem;

  @include media-breakpoint-up(md) {
    margin-left: 2rem;
    flex: 1 1 17rem;
    padding: 0;
    margin-top: 9rem;
    margin-bottom: 3rem;
  }

  .tip-card {
    @include media-breakpoint-up(md) {
      min-height: 32rem;
    }
  }

  @include media-breakpoint-up(lg) {
    flex: 0 1 26rem;
  }
}

.date-picker {
  width: 8rem;
}
.btn-circle {
  width: 22px;
  height: 22px;
  padding: 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 8px;
  line-height: 1.42857;
  background-color: $white;
  font-weight: bold;
  color: $black;
}

.font-smaller {
  font-size: 0.875rem;
}
.font-larger {
  font-size: 1.125rem;
}

.fake-border-selected {
  box-shadow: 0 0 0 2px $primary;
}

.react-calendar {
  max-width: 100%;
  background: white;

  padding: 1rem 1rem 2rem 1rem;
  font-family: $font-family-sans-serif;
  line-height: 1.125em;

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  button {
    margin: 0;
    border: 0;
    outline: none;

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__navigation {
    height: 44px;
    margin-bottom: 1em;
    &__next2-button,
    &__prev2-button {
      display: none;
    }

    &__label {
      flex-grow: 0 !important;
      color: $primary;
      min-width: 160px;
      background: none;
      font-weight: bold;
      font-size: 1.25rem;
    }
    &__next-button,
    &__prev-button {
      min-width: 44px;
      background: none;
      color: $primary;
      &[disabled] {
        color: $ddd;
      }
    }
  }

  &__month-view {
    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;

      &__weekday {
        padding: 0.5em;
        font-size: 1.125rem;
        font-weight: bold;
        abbr {
          text-decoration: none;
        }
      }
    }

    &__weekNumbers {
      font-weight: bold;

      .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
      }
    }

    &__days {
      &__day {
        &--neighboringMonth {
          color: rgb(117, 117, 117);
        }
      }
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      padding: 2em 0.5em;
    }
  }

  &__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
    font-size: 1.125rem;
    border-radius: 0.5rem;

    &:enabled {
      &:hover,
      &:focus {
        background-color: rgb(230, 230, 230);
      }
    }

    &--hasActive {
      background: $primary;

      &:enabled {
        &:hover,
        &:focus {
          background: $primary, 10%;
        }
      }
    }

    &--active {
      background: $primary;
      color: white;
      font-weight: bold;
      &:enabled {
        &:hover,
        &:focus {
          background: $primary;
        }
      }
    }
  }

  &--selectRange {
    .react-calendar__tile {
      &--hover {
        background-color: rgb(230, 230, 230);
      }
    }
  }
}
.col-0 {
  flex-grow: 0;
  width: 0;
}
.col-pairwise {
  flex: 0 0 calc(50% - 42px);
  max-width: calc(50% - 42px);
}
.overflow-scroll-y {
  overflow-y: scroll;
}

.draggable {
  cursor: pointer; /* fallback: no `url()` support or images disabled */
}

.draggable:active {
  // cursor: url(images/grabbing.cur);
  cursor: grabbing;
}

[data-reach-slider] {
  height: 6px !important;
}
[data-reach-slider-track] {
  border-radius: 4px !important;
}
[data-reach-slider-handle] {
  width: 1rem !important;
  height: 1rem !important;
}

.ellipsis-spinner:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.nav-user-profile {
  border-radius: 50% 50%;
  max-width: 2.75rem;
  height: auto;
}
.profile-img-pos {
  top: 0px;
  left: 50%;
  margin-left: -75px;

  margin-top: -48px;
}
.profile-img {
  width: 98px;
  border-radius: 50% 50%;
  border: 5px solid #fff;

  top: 0px;
  left: 50%;
  margin-left: -48px;
  margin-top: -48px;
}

.search-line,
.search-bar {
  .delete-icon {
    position: absolute;
    right: 4rem;
    top: 1rem;
    cursor: pointer;
    z-index: 1000;
  }

  input {
    height: 56px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    font-weight: bold;
    padding-right: 3rem !important;
  }

  .last-updated {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $secondary;
  }

  .updating-label {
    background-color: $success;
    font-weight: bold;
    color: white;
    border-radius: $border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 44px;

    &.updating {
      background-color: $primary;

      .icon {
        -webkit-animation: spin 2s ease-in-out infinite;
        -moz-animation: spin 2s ease-in-out infinite;
        animation: spin 2s ease-in-out infinite;
      }
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.show-error-label {
  position: absolute;
  bottom: 5px;
  right: 35px;
}

.modal-button-margin {
  margin-left: 25px;
  margin-top: 15px;
  margin-bottom: 25px;
}

.button-width {
  width: 35%;
}

.reddish-background {
  background-color: #fbf0f0 !important;
}

.candidate-grade-change-modal,
.remove-user-modal {
  min-width: 680px;

  .modal-header-cj button.close span {
    color: $black37;
    font-size: 2.5rem;
  }

  &.disabled .modal-header-cj button.close {
    display: none;
  }
}

.candidate-row {
  margin: 0;

  color: $secondary;
  font-size: 18px;
  align-items: center;

  border-right: $candidate-list-border;
  border-left: $candidate-list-border;

  &:nth-child(even) {
    background-color: $candidate-bg;
  }

  &:last-child {
    border-bottom: $candidate-list-border;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .name {
    color: black;
    font-weight: bold;

    & > * {
      display: block;
      line-height: 18px;
    }

    .candidate-uln {
      color: $secondary;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.candidate-grade-change-modal,
.candidate-row {
  .candidate-grade {
    background-color: $ddd;
    font-weight: bold;
    line-height: 22px;
    font-size: 20px;
    border-radius: 0.5rem;
    color: black;
    width: fit-content;
    margin: 0 auto;
  }
}

.approval-label {
  background-color: #efefef;
  padding: 10px;
  border-radius: 8px;
  font-size: small;
}

@media (min-width: 375px) {
  .d-mm-block {
    display: block !important;
  }
  .d-mm-flex {
    display: flex !important;
  }
}

@media (min-width: 425px) {
  .d-ml-block {
    display: block !important;
  }
  .d-ml-flex {
    display: flex !important;
  }
}

.font-weight-semi-bold {
  font-weight: 600;
}
.with-bullets {
  list-style-type: disc !important;
  padding-left: 1em;
  li {
    display: list-item;
  }
}

// Markdown styles

.markdown-content {
  h4 {
    color: $primary;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 12px;
  }
  h5 {
    font-weight: bold;
  }

  a,
  code {
    color: $primary;
    font-family: $font-family-sans-serif;
    font-size: 1rem;
    font-weight: 700;
  }
}

.declaration-form {
  ul {
    padding-left: 0;
  }
  li {
    list-style: none;
    input {
      width: 24px;
      height: 24px;
      background: $primary;
      margin-right: 20px;
      height: 100%;
    }
  }
}

.holding-page {
  margin-top: 64px;
  text-align: center;
  padding: 48px 20%;
  border-radius: 8px;
  font-size: 18px;
  h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  code {
    color: $primary;
    font-family: $font-family-sans-serif;
    font-size: 18px;
  }
}

.landing-page__container {
  padding-top: 73px;

  .landing-page__title {
    font-size: $font-large;
    font-weight: bold;
  }
}

.aam-resource__link {
  display: flex;

  color: $primary;
  font-weight: bold;

  padding: 0.25em 0;
  align-items: center;

  .aam-resource__icon {
    margin-right: 1em;
    font-size: 1.5em;
  }
}
