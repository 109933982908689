$bu: ci;
@import 'src/custom';

.hoc-approval-modal {
  .hoc-approval-modal-header {
    width: $modal-md;
  }
  .hoc-approval-modal-body {
    padding-bottom: 50px;
    width: $modal-md;
    .modal-body-text {
      width: 500px;
    }
  }
  .hoc-approval-modal-footer {
    width: $modal-md;
  }
}
