$bu: ci;
.not-available {
  background-color: #fafafa;
  padding: 50px;
  .fa-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    width: 50%;
    margin: auto;
    text-align: center;
  }
}
