$bu: ci;
.extend-deadline-modal {
  .custom-input {
    position: relative;

    svg {
      position: absolute;
      right: 1rem;
      top: 1.2rem;
    }
  }
}
