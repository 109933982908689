$bu: ci;
@import '../../../../custom';

.candidates-filter {
  .candidates-filter-col {
    padding-bottom: 0.5rem;

    &:first-child {
      .candidates-filter-label {
        font-size: 18px;
      }
    }
    .candidates-filter-tab {
      text-align: center;
      border-radius: $border-radius;
      background-color: $lightButNotTooLight;
      border: 1px solid $lightButNotTooLight;
      height: 100%;

      @include media-breakpoint-down(md) {
        font-size: 12px;
      }

      &:not(.empty),
      &:not(.empty) * {
        cursor: pointer;
      }

      &.empty {
        background-color: white;
        border: 1px solid $ddd;
      }

      &.active {
        background-color: $primary;
        &,
        .candidates-filter-count {
          color: white;
        }
      }

      & > * {
        margin: 0;
        display: block;
        font-weight: bold;
      }

      .candidates-filter-count {
        color: $secondary;
      }
    }
  }
  .icon-red {
    color: $danger !important;
  }
}
