$bu: ci;
@import '../../custom';

.flow-info {
  .div-circle {
    background-color: $primary;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    color: white;
    font-weight: bold;
    margin: auto;
  }
}
