$bu: ci;
@import '../../../custom';

.add-user-form {
  .add-user-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-width: 145px;
    height: 40px;
    background-color: $primary;
    opacity: 0.59;
    border-radius: $border-radius;
    font-weight: bold;
    color: white;

    &.enabled {
      cursor: pointer;
      opacity: 1;
    }
  }
}
